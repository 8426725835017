import React, { ReactNode, useCallback, useState } from 'react'
import { Object } from 'ts-toolbelt'
import { useClient, useMutation } from 'urql'

import {
  useOrderingTypeContext,
  useVenueContext,
} from '@/contexts/VenueOrderContext'
import {
  AddOrUpdateTableNumberOnCartDocument,
  CartTableInfoDocument,
  CartTableInfoQuery,
  CartTableInfoQueryVariables,
  OrderingType,
  VenueMenuCategoryDocument,
  VenueMenuCategoryQuery,
  VenueMenuCategoryQueryVariables,
} from '@/gql/graphql'
import { useEffectOnce } from '@/hooks/useEffectOnce'
import { useLogger } from '@/hooks/useLogger'
import { CartDefaults } from '@/lib/utils'

/*
  To get a cart, we need a table number from query param.
  But then we want the cart->tableNumber to be the source of truth afterwards.
  In order to make this simpler, we do it sequentially and pre-fetch categories once
  we know which table number is correct.

  If query table number exists, check if cart matches it first - otherwise update it.
  This way the child components in VenuePage don't have to deal with conflicting source of truth
  for the table number to use. This results in not querying VenueMenuCategory without a table number (if it exists)
  We still query cartLanding inside VenuePage without a table number but that doesn't seem to matter as long as you pass the correct one
  to begin with.
*/
export const ResolveCart = ({
  tableNumber,
  tableArea,
  children,
  loader,
}: {
  children: ReactNode
  loader: ReactNode
} & CartDefaults) => {
  const client = useClient()
  const { venueSlug } = useVenueContext()
  const { orderingType } = useOrderingTypeContext()
  /*
    We want to automatically resolve and continue rendering VenuePage if:
    - Ordering type is visual menu as there is no cart
    - Venue is not using embedded query params (table number/table area)
  */
  const [resolved, setResolved] = useState<boolean>(
    orderingType === OrderingType.Menu || !tableNumber ? true : false,
  )
  const { logEvent } = useLogger()
  const [{ fetching: updating }, addOrUpdateTableNumberOnCart] = useMutation(
    AddOrUpdateTableNumberOnCartDocument,
  )

  const updateTableNumber = useCallback(
    async ({
      tableNumber,
      tableArea,
    }: Object.Required<CartDefaults, 'tableNumber'>) => {
      await addOrUpdateTableNumberOnCart({
        input: {
          venueSlug,
          orderingType,
          tableArea,
          tableNumber,
        },
      })
      logEvent('Updated embedded table number', {
        venueSlug,
        orderingType,
        tableArea,
        tableNumber,
      })
    },
    [addOrUpdateTableNumberOnCart, venueSlug, orderingType, logEvent],
  )

  const preFetchCategories = useCallback(
    (props: VenueMenuCategoryQueryVariables) => {
      return client
        .query<
          VenueMenuCategoryQuery,
          VenueMenuCategoryQueryVariables
        >(VenueMenuCategoryDocument, props)
        .toPromise()
    },
    [client],
  )

  const resolveCart = useCallback(
    async ({
      tableNumber,
      tableArea,
    }: Object.Required<CartDefaults, 'tableNumber'>) => {
      const [{ data }] = await Promise.all([
        client
          .query<CartTableInfoQuery, CartTableInfoQueryVariables>(
            CartTableInfoDocument,
            {
              orderingType,
              venueSlug,
              tableArea,
              tableNumber,
            },
          )
          .toPromise(),
      ])

      const cart = data?.getCart

      if (!cart) {
        return
      }

      const cartTableNumber = (cart.tableNumber ?? '').toLowerCase()
      const cartTableArea = (cart.tableArea ?? '').toLowerCase()

      // pre-fetch categories so we don't need to wait for stale cart to be re-fetched
      // if table number does not match
      void preFetchCategories({
        orderingType,
        venueSlug,
        tableNumber,
        tableArea: tableArea ?? cartTableArea ?? '',
        orderingWindowStartDate: cart.orderingWindowStartDate ?? undefined,
      })

      // check table number matches cart, if not update it
      if (
        cartTableNumber !== (tableNumber ?? '').toLowerCase() ||
        cartTableArea !== (tableArea ?? '').toLowerCase()
      ) {
        await updateTableNumber({ tableNumber, tableArea })
      }

      setResolved(true)
    },
    [client, venueSlug, orderingType, updateTableNumber, preFetchCategories],
  )

  useEffectOnce(() => {
    if (!resolved && tableNumber) {
      void resolveCart({ tableNumber, tableArea })
    }
  })

  return <>{resolved && !updating ? children : loader}</>
}
