import { RouteLink } from '@mr-yum/frontend-core/dist/services/routes'
import { KeyFilledIcon } from '@mr-yum/frontend-ui'
import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

import { useLogger } from '@/hooks/useLogger'
import { routes } from '@/lib/routes'

import { ActionBlockItem } from './ActionBlockItem'

interface Props {
  venueSlug: string
  memberName?: string | null
  count: number
}

export const LoyaltyMembershipBlock = ({
  venueSlug,
  memberName,
  count,
}: Props) => {
  const intl = useIntl()
  const { logEvent } = useLogger()
  const heading = memberName ?? (
    <FormattedMessage defaultMessage="Members" id="+a+2ug" />
  )

  return (
    <RouteLink route={routes.loyaltyMembership} pathData={{ venueSlug }}>
      <ActionBlockItem
        count={count}
        icon={<KeyFilledIcon />}
        onClick={() =>
          logEvent('Clicked on loyalty membership on venue landing page')
        }
        aria-label={intl.formatMessage({
          defaultMessage: 'Members',
          id: '+a+2ug',
        })}
        heading={heading}
      />
    </RouteLink>
  )
}
