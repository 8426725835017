import { createDinero } from '@mr-yum/frontend-core/dist/services/createDinero'
import { RouteLink } from '@mr-yum/frontend-core/dist/services/routes'
import { Button } from '@mr-yum/frontend-ui'
import React, { useEffect, useRef } from 'react'

import {
  useOrderingTypeContext,
  useVenueContext,
} from '@/contexts/VenueOrderContext'
import { useCartPage } from '@/hooks/useCart'
import { routes } from '@/lib/routes'

import { getCartItemCount } from './utils'

export const CheckoutButton = () => {
  const { venueSlug } = useVenueContext()
  const { orderingType, orderingTypeSlug } = useOrderingTypeContext()
  const { cart } = useCartPage({
    venueSlug,
    orderingType,
    requestPolicy: 'cache-and-network',
  })

  const subTotalInCents = cart?.total.subTotalInCents
  const previousItemCount = useRef<number | null>(null)
  const previousSubtotalInCents = useRef<number | undefined | null>(null)

  const itemCount = getCartItemCount(cart)

  useEffect(() => {
    previousItemCount.current = itemCount
  }, [itemCount])

  useEffect(() => {
    previousSubtotalInCents.current = subTotalInCents
  }, [subTotalInCents])

  if (!subTotalInCents || !itemCount) {
    return null
  }

  const currentSubtotal = createDinero(
    subTotalInCents,
    cart.currency,
  ).toFormat()

  return (
    <RouteLink route={routes.cart} pathData={{ venueSlug, orderingTypeSlug }}>
      <Button
        className="overflow-clip rounded-full shadow-lg animate-in slide-in-from-bottom"
        size={'lg'}
        as="a"
      >
        <div className="relative mr-2 flex size-5 shrink-0 items-center justify-center overflow-hidden rounded bg-[color:var(--action-primary-foreground)] text-center text-[color:var(--action-primary-surface)] my-label-md">
          {!!previousItemCount.current && (
            <span
              key={`old-${previousItemCount.current}`}
              className="absolute opacity-0 duration-300 animate-out slide-out-to-top-4"
            >
              {previousItemCount.current}
            </span>
          )}
          <span
            key={`new-${itemCount}`}
            className="absolute duration-300 animate-in fade-in slide-in-from-bottom-4"
          >
            {itemCount}
          </span>
        </div>
        <div className="relative overflow-hidden my-label-md">
          Checkout · <span className="opacity-0">{currentSubtotal}</span>
          {!!previousSubtotalInCents.current && (
            <span
              className="absolute right-0 opacity-0 duration-300 animate-out slide-out-to-top-8"
              key={`old-${previousSubtotalInCents.current}`}
            >
              {createDinero(
                previousSubtotalInCents.current,
                cart.currency,
              ).toFormat()}
            </span>
          )}
          <span
            className="absolute right-0 flex-grow duration-300 animate-in fade-in slide-in-from-bottom-8"
            key={`new-${subTotalInCents}`}
          >
            {currentSubtotal}
          </span>
        </div>
      </Button>
    </RouteLink>
  )
}
