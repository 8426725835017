import { TransformType } from '@mr-yum/cdn-image'
import { isServer } from '@mr-yum/frontend-core/dist/support/env'
import { formatPhoneNumber } from '@mr-yum/frontend-core/dist/support/phone'
import {
  CloseIcon,
  cn,
  IconButton,
  Logo,
  MenuAltIcon,
  MoreHorizontalIcon,
  Sheet,
  SheetContent,
} from '@mr-yum/frontend-ui'
import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { useQuery } from 'urql'

import { Image } from '@/components/Shared/Image'
import {
  HamburgerMenuAdditionalDocument,
  HamburgerMenuDocument,
  OrderingType,
} from '@/gql/graphql'
import { useLogger } from '@/hooks/useLogger'

import { VenueMenu } from './VenueMenu'

interface Props {
  venueSlug: string
  orderingType: OrderingType
  className?: string
  isMoreHorizontal?: boolean
}

export const HamburgerMenu = ({
  venueSlug,
  orderingType,
  className,
  isMoreHorizontal,
}: Props) => {
  const intl = useIntl()
  const { logEvent } = useLogger()
  const [open, setOpen] = useState(false)

  const [{ data: venueData, fetching: venueFetching }] = useQuery({
    query: HamburgerMenuDocument,
    pause: isServer || !open,
    variables: { venueSlug },
  })

  const [{ data: additionalData, fetching: additionalFetching }] = useQuery({
    query: HamburgerMenuAdditionalDocument,
    pause: isServer || !open,
    variables: { venueSlug },
  })

  const venue = venueData?.guestVenue

  const handleOpenHamburgerMenu = () => {
    logEvent('Click on hamburger menu')
    setOpen(true)
  }

  return (
    <Sheet open={open} onOpenChange={setOpen}>
      <button
        className={cn(
          className,
          'flex h-14 w-14 flex-shrink-0 items-center justify-center truncate rounded-none border-transparent bg-transparent py-3 text-foreground-ui-navbar focus:outline-none active:border-transparent',
        )}
        onClick={handleOpenHamburgerMenu}
        aria-label="Open menu"
      >
        {!isMoreHorizontal ? (
          <MenuAltIcon />
        ) : (
          <MoreHorizontalIcon
            width={20}
            height={20}
            className="flex-shrink-0"
          />
        )}
      </button>
      <SheetContent side="left">
        <div className="relative flex h-full w-[calc(100vw-48px)] max-w-[280px] flex-col bg-surface text-foreground">
          <IconButton
            className="absolute right-1 top-1"
            onClick={() => setOpen(false)}
            aria-label={intl.formatMessage({
              defaultMessage: 'Close menu',
              id: 'dg8ZEb',
            })}
          >
            <CloseIcon />
          </IconButton>

          <header className="px-8 pt-14">
            <div className="relative mb-14 h-12 w-full">
              {venue?.logo ? (
                <Image
                  image={venue.logo}
                  className="!object-contain !object-left"
                  transform={TransformType.AVATAR}
                  alt={venue?.name ?? ''}
                  layout="fill"
                  objectFit="contain"
                />
              ) : (
                <Logo height={38} className="inline-block" />
              )}
            </div>
          </header>

          <VenueMenu
            orderingType={orderingType}
            venueSlug={venueSlug}
            venue={venue}
            currentUser={additionalData?.currentUser}
            lastOrder={additionalData?.lastOrderByUserByVenueSlug}
            venueFetching={venueFetching}
            additionalFetching={additionalFetching}
          />

          <div className="flex-1" />

          <footer className="px-8 pb-12">
            {venue?.location?.formattedAddress && (
              <a
                href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
                  venue.location.formattedAddress,
                )}`}
                className="mb-5 block text-inherit my-body-sm hover:underline"
                target="_blank"
                rel="noopener noreferrer"
              >
                {venue.location.formattedAddress}
              </a>
            )}

            {venue?.supportPhone && (
              <a
                href={`tel:${venue?.supportPhone}`}
                className="mb-5 block text-inherit my-body-sm hover:underline"
              >
                {formatPhoneNumber(String(venue?.supportPhone), true)}
              </a>
            )}

            {venue?.supportEmail && (
              <a
                href={`mailto:${venue?.supportEmail}`}
                className="mb-5 block truncate text-inherit my-body-sm hover:underline"
              >
                {venue?.supportEmail}
              </a>
            )}
          </footer>
        </div>
      </SheetContent>
    </Sheet>
  )
}
