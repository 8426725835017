import { isServer } from '@mr-yum/frontend-core/dist/support/env'
import Cookies from 'js-cookie'
import { useIntl } from 'react-intl'
import { CombinedError, useQuery } from 'urql'

import { LoyaltyPointsDocument } from '@/gql/graphql'

import { AuraMemberShipCookie } from './useAuraOAuth'

interface Props {
  venueSlug: string
}

interface Payload {
  points: number | null
  pointName: string
  fetching: boolean
  error?: CombinedError
}

export const useLoyaltyPoints = ({ venueSlug }: Props): Payload => {
  const intl = useIntl()

  const [{ data, fetching, error }] = useQuery({
    query: LoyaltyPointsDocument,
    variables: {
      venueSlug,
      externalMemberId:
        Cookies.get(AuraMemberShipCookie + '-' + venueSlug) || null,
    },
    pause: isServer,
  })

  const { guestData, metadata, features } = data?.loyaltyDetails || {}

  const pointName =
    metadata?.pointName ||
    intl.formatMessage({ defaultMessage: 'points', id: 'KYUOY2' })

  if (fetching || !features?.canShowPointBalance || !guestData?.isOptedIn) {
    return {
      points: null,
      pointName,
      fetching,
      error,
    }
  }

  return {
    points: guestData?.points,
    pointName,
    fetching,
    error,
  }
}
