import React from 'react'

import { Text } from '@/components/Skeleton/Text'

interface Props {
  count?: number
}

export const MenuCategoriesLoader = ({ count = 2 }: Props) => {
  const categoryCount = Math.max(count, 1)

  return (
    <>
      {Array.from({ length: categoryCount }).map((_, index) => (
        <div className="w-full" key={index} data-testid="menu-category-loader">
          <div className="relative z-[1] mb-3 h-0 w-full overflow-hidden rounded bg-surface-subtle p-4 pt-[57%] skeleton"></div>

          <div className="h-[20px]">
            <Text chars={16} />
          </div>
        </div>
      ))}
    </>
  )
}
