import { isServer } from '@mr-yum/frontend-core/dist/support/env'
import filter from 'lodash/filter'
import find from 'lodash/find'
import { IntlShape } from 'react-intl'
import { useQuery } from 'urql'

import {
  useOrderingTypeContext,
  useVenueContext,
} from '@/contexts/VenueOrderContext'
import {
  CartLandingQuery,
  HamburgerMenuAdditionalQuery,
  HamburgerMenuQuery,
  OrderingType,
  OrderingTypeSetting,
  OrderingWindowSettings,
  VenueLandingAdditionalQuery,
  VenueLandingQuery,
  VenueMenuCategoryDocument,
} from '@/gql/graphql'
import { SheetSlug } from '@/lib/routes'

export type OrderingTypeVenueValidator = {
  orderingTypes: Array<
    Pick<
      OrderingTypeSetting,
      | 'orderingType'
      | 'id'
      | 'isAvailable'
      | 'usingTableGroups'
      | 'tablePromptText'
      | 'landingText'
      | 'name'
      | 'tableShortText'
    > & {
      orderingWindow?: Pick<OrderingWindowSettings, 'enabled' | 'id'> | null
    }
  >
}

export const getNameForOrderingType = (
  type: OrderingType,
  { formatMessage }: IntlShape,
) => {
  switch (type) {
    case OrderingType.DineIn:
      return formatMessage({ defaultMessage: 'Dine-in', id: 'rUE5rh' })
    case OrderingType.PickUp:
      return formatMessage({ defaultMessage: 'Pickup', id: '7uP+Kl' })
    case OrderingType.Delivery:
      return formatMessage({ defaultMessage: 'Delivery', id: 'drqP2L' })
    case OrderingType.Catering:
      return formatMessage({ defaultMessage: 'Catering', id: 'OdZ0z5' })
    case OrderingType.InVenue:
      return formatMessage({
        defaultMessage: 'In-venue delivery',
        id: '1CH5xv',
      })
    case OrderingType.Counter:
      return formatMessage({ defaultMessage: 'Counter pickup', id: 'kYbb+b' })
    default:
      return formatMessage({ defaultMessage: 'Visual menu', id: 'RubZ1T' })
  }
}

export const findSettingsByOrderingType = ({
  venue,
  orderingType,
}: {
  venue?: OrderingTypeVenueValidator | null
  orderingType: OrderingType
}) => {
  if (!venue) return undefined

  return find(venue.orderingTypes, {
    orderingType,
  })
}

export const findSettingsByOrderingTypeV2 = ({
  orderingTypes,
  orderingType,
}: {
  orderingTypes: NonNullable<VenueLandingQuery['guestVenue']>['orderingTypes']
  orderingType: OrderingType
}) => {
  return orderingTypes.find((o) => o.orderingType === orderingType)
}

export const hasOtherOrderingTypes = (
  venue: VenueLandingQuery['guestVenue'],
  orderingType: OrderingType,
) => {
  if (!venue) return false

  const orderingTypes = filter(venue.orderingTypes, {
    orderingType: !orderingType,
  })

  return orderingTypes.length > 0
}

export const getOrderingTypeName = (
  venue: VenueLandingQuery['guestVenue'],
  orderingType: OrderingType,
  intl: IntlShape,
): string => {
  const setting = findSettingsByOrderingType({
    venue,
    orderingType,
  })
  const fallbackName = getNameForOrderingType(orderingType, intl)

  return setting?.name || fallbackName
}

export interface OrderingTypeItem {
  id: string
  name: string
  orderingType: OrderingType
  defaultSheet?: SheetSlug
  isActive: boolean
  isEnabled?: boolean
}

export const venueToOrderingTypeItems = (
  venue: VenueLandingQuery['guestVenue'],
  orderingType: OrderingType,
  intl: IntlShape,
) => {
  let nonMenuCount = 0
  const ignoredOrderingTypes = [OrderingType.Menu]

  const items: OrderingTypeItem[] = []

  const hasOrderingTypes = (venue?.orderingTypes || []).length > 0
  const acceptingOrders = venue?.ordering && venue?.isOrderingAvailable

  if (venue?.alwaysShowVisualMenu || (!acceptingOrders && hasOrderingTypes)) {
    items.push({
      id: 'menu',
      name: getOrderingTypeName(venue, OrderingType.Menu, intl),
      orderingType: OrderingType.Menu,
      isActive: orderingType === OrderingType.Menu,
      isEnabled: true,
    })
  }

  if (acceptingOrders) {
    venue?.orderingTypes
      ?.filter((type) => !ignoredOrderingTypes.includes(type.orderingType))
      .map((type) => {
        items.push({
          id: type.id,
          name: getOrderingTypeName(venue, type.orderingType, intl),
          orderingType: type.orderingType,
          isActive: orderingType === type.orderingType,
          isEnabled: isOrderingTypeAvailable(venue, type.orderingType),
        })

        nonMenuCount++
      })
  }

  return { nonMenuCount, items }
}

export function isOrderingTypeAvailable(
  venue: Exclude<
    VenueLandingQuery['guestVenue'] | HamburgerMenuQuery['guestVenue'],
    null | undefined
  >,
  orderingType: OrderingType,
) {
  const orderingTypeSettings = venue?.orderingTypes.find(
    (setting) => setting.orderingType === orderingType,
  )

  switch (orderingType) {
    case OrderingType.DineIn:
      // ordering + dine in is on and venue is open
      return (
        venue.isOrderingAvailable &&
        !venue.isClosed &&
        orderingTypeSettings?.isAvailable
      )
    case OrderingType.Counter:
    case OrderingType.Delivery:
    case OrderingType.PickUp:
      // ordering + pickup is available and either available for pre-order or venue is open
      return (
        venue.isOrderingAvailable &&
        orderingTypeSettings?.isAvailable &&
        (orderingTypeSettings?.orderingWindow?.enabled || !venue.isClosed)
      )
    case OrderingType.InVenue:
      return (
        venue.isOrderingAvailable &&
        orderingTypeSettings?.isAvailable &&
        !venue.isClosed
      )
    case OrderingType.Menu:
      return true
    default:
      return false
  }
}

export const getReorderId = (
  lastOrder:
    | HamburgerMenuAdditionalQuery['lastOrderByUserByVenueSlug']
    | VenueLandingAdditionalQuery['lastOrderByUserByVenueSlug'],
  venue: VenueLandingQuery['guestVenue'] | HamburgerMenuQuery['guestVenue'],
  orderingType: OrderingType,
): { reorderId: string | null } => {
  if (!lastOrder?.id || !venue) {
    return {
      reorderId: null,
    }
  }

  if (
    lastOrder.orderingType === orderingType &&
    venue.ordering &&
    venue.isOrderingAvailable &&
    isOrderingTypeAvailable(venue, orderingType)
  ) {
    return {
      reorderId: lastOrder.id,
    }
  } else {
    return { reorderId: null }
  }
}

type VenueMenuCategoryQueryProps = {
  cart: CartLandingQuery['getCart']
  cartFetching: boolean
}

const shouldPauseVenueMenuCategoryQuery = ({
  orderingType,
  cart,
  cartFetching,
}: {
  orderingType: OrderingType
} & VenueMenuCategoryQueryProps) => {
  if (isServer) {
    return true
  }
  // visual menu doesn't have a cart so we just fetch categories straight away
  if (orderingType === OrderingType.Menu) {
    return false
  }

  // resolve cart before fetching categories
  const cartResolved = cart && !cartFetching
  return !cartResolved
}

export const useVenueMenuCategory = ({
  cart,
  cartFetching,
}: VenueMenuCategoryQueryProps) => {
  const { venueSlug } = useVenueContext()
  const { orderingType } = useOrderingTypeContext()
  const pause = shouldPauseVenueMenuCategoryQuery({
    cart,
    cartFetching,
    orderingType,
  })

  const [{ data, error, fetching, stale }] = useQuery({
    query: VenueMenuCategoryDocument,
    variables: {
      venueSlug,
      orderingType,
      tableNumber: cart?.tableNumber ?? '',
      tableArea: cart?.tableArea ?? '',
      orderingWindowStartDate: cart?.orderingWindowStartDate ?? undefined,
    },
    pause,
    requestPolicy: 'cache-and-network',
  })

  return {
    data,
    error,
    fetching,
    stale,
  }
}
