import { VenueLandingAdditionalQuery } from '@/gql/graphql'

export const deduplicateCartItems = (
  cartItems: VenueLandingAdditionalQuery['getAnotherRoundCartItems'],
) => {
  const seenItemIds = new Set()

  return cartItems?.filter((cartItem) => {
    if (seenItemIds.has(cartItem.item?.id)) {
      return false
    }
    seenItemIds.add(cartItem.item?.id)
    return true
  })
}
