import { RouteLink } from '@mr-yum/frontend-core/dist/services/routes'
import { UserGroupOutlineIcon } from '@mr-yum/frontend-ui'
import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

import { useLogger } from '@/hooks/useLogger'
import { routes } from '@/lib/routes'

import { ActionBlockItem } from './ActionBlockItem'

interface Props {
  venueSlug: string
  count: number
  tabId: string
}

export const ManageTabBlock = ({ venueSlug, count, tabId }: Props) => {
  const intl = useIntl()
  const { logEvent } = useLogger()

  return (
    <RouteLink route={routes.socialTabManage} pathData={{ venueSlug, tabId }}>
      <ActionBlockItem
        count={count}
        icon={<UserGroupOutlineIcon />}
        onClick={() =>
          logEvent('Clicked on on manage my tab on venue landing page')
        }
        aria-label={intl.formatMessage({
          defaultMessage: 'Manage my group order',
          id: 'w5/OUl',
        })}
        heading={
          <FormattedMessage
            defaultMessage="Manage my group order"
            id="w5/OUl"
          />
        }
      />
    </RouteLink>
  )
}
