import React from 'react'

import { MenuCategoryCardItem } from '@/components/Venue/Categories/MenuCategoryCardItem'
import { MenuCategoryListItem } from '@/components/Venue/Categories/MenuCategoryListItem'
import { OrderingType, VenueMenuCategoryQuery } from '@/gql/graphql'
import { OrderingTypeSlug } from '@/lib/routes'

interface Props {
  venueSlug: string
  orderingType: OrderingType
  orderingTypeSlug: OrderingTypeSlug
  categories: VenueMenuCategoryQuery['guestMenuCategories']
  orderingWindowEnabled: boolean
  listViewEnabled: boolean
  isTwoColumn: boolean
}

export const MenuCategories = ({
  venueSlug,
  orderingType,
  orderingTypeSlug,
  categories,
  orderingWindowEnabled,
  listViewEnabled,
  isTwoColumn,
}: Props) => {
  const CategoryItemComponent = listViewEnabled
    ? MenuCategoryListItem
    : MenuCategoryCardItem

  return (
    <>
      {categories.map((category, i) => {
        // Load first three card images eagerly
        const isPriorityImage = i < 3

        return (
          <CategoryItemComponent
            key={category.id}
            category={category}
            venueSlug={venueSlug}
            orderingTypeSlug={orderingTypeSlug}
            unavailable={
              category.isClosed &&
              !orderingWindowEnabled &&
              orderingType !== OrderingType.Menu
            }
            priorityImage={isPriorityImage}
            isTwoColumn={isTwoColumn}
          />
        )
      })}
    </>
  )
}
