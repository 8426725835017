import { Logo } from '@mr-yum/frontend-ui'
import React from 'react'

import { Image } from '@/components/Shared/Image'

interface Props {
  venueLogo: any
}

export const VenueLogo = ({ venueLogo }: Props) => {
  return (
    <div className="absolute bottom-0 flex w-full justify-start px-3 pb-9">
      <div className="relative flex h-[58px] w-[80px] justify-center overflow-hidden rounded-xl bg-surface-ui-background shadow-xl">
        {venueLogo ? (
          <Image
            image={venueLogo}
            className="!object-contain"
            objectFit="contain"
            layout="fill"
          />
        ) : (
          <Logo width={70} height={58} className="relative inline-block" />
        )}
      </div>
    </div>
  )
}
