import React from 'react'

import { ProfileNavBarIcon } from '@/components/GuestProfile/ProfileNavbarIcon'
import { HamburgerMenu } from '@/components/HamburgerMenu/HamburgerMenu'
import {
  useOrderingTypeContext,
  useVenueContext,
} from '@/contexts/VenueOrderContext'

export const VenueHeaderInfo = ({
  venueName,
  venueId,
}: {
  venueName: string
  venueId: string
}) => {
  const { venueSlug } = useVenueContext()
  const { orderingType } = useOrderingTypeContext()
  return (
    <div className="mb-1 flex w-full items-center justify-start gap-5">
      <h1 className="line-clamp-3 flex-grow my-heading-lg">{venueName}</h1>
      <ProfileNavBarIcon
        venueSlug={venueSlug}
        venueId={venueId}
        venueName={venueName}
        className="!h-[36px] !w-[36px] !rounded-full !bg-surface-bright !py-0 !text-foreground"
        isUserFilledIcon
      />
      <HamburgerMenu
        orderingType={orderingType}
        venueSlug={venueSlug}
        className="!h-[36px] !w-[36px] !rounded-full !bg-surface-bright !py-0 !text-foreground"
        isMoreHorizontal
      />
    </div>
  )
}
