import React from 'react'

import { ProfileNavBarIcon } from '@/components/GuestProfile/ProfileNavbarIcon'
import { HamburgerMenu } from '@/components/HamburgerMenu/HamburgerMenu'
import { OrderingType } from '@/gql/graphql'

export interface VenueHeaderBarProps {
  venueId: string
  venueName: string
  venueSlug: string
  orderingType: OrderingType
}

export const VenueHeaderBar = ({
  venueId,
  venueName,
  venueSlug,
  orderingType,
}: VenueHeaderBarProps) => {
  return (
    <div className="js-dialog-offset fixed inset-x-0 top-0 z-10 flex h-14 items-center justify-between bg-surface-ui-navbar pl-4 text-foreground-ui-navbar">
      <h1 className="flex-grow truncate my-heading-sm">{venueName}</h1>

      <ProfileNavBarIcon
        venueSlug={venueSlug}
        venueId={venueId}
        venueName={venueName}
      />
      <HamburgerMenu orderingType={orderingType} venueSlug={venueSlug} />
    </div>
  )
}
