import { Button, ChevronDownIcon } from '@mr-yum/frontend-ui'
import React, { useCallback } from 'react'
import { useIntl } from 'react-intl'

import { CartValidator } from '@/components/Cart/validation'
import { Text } from '@/components/Skeleton/Text'
import { TableNumberLabel } from '@/components/TableNumber/TableNumberLabel'
import { OrderingType } from '@/gql/graphql'
import { useLogger } from '@/hooks/useLogger'

import { useSheetToggle } from './utils/sheetUtils'

interface Props {
  orderingType: OrderingType
  cart?: CartValidator | null
  tableShortText?: string
  isLoading: boolean
  isTableTurnedOff?: boolean
  tableNumber?: string | null
  hasNoPaths?: boolean
}

export const TableNumberButton = ({
  orderingType,
  cart,
  tableShortText,
  isLoading,
  isTableTurnedOff,
  hasNoPaths,
  tableNumber,
}: Props) => {
  const { logEvent } = useLogger()
  const sheetToggle = useSheetToggle()
  const intl = useIntl()

  const handleOnClick = useCallback(() => {
    logEvent('Clicked on table number selector')
    void sheetToggle({
      sheet: 'table-number',
    })
  }, [logEvent, sheetToggle])

  if (isLoading || !cart) {
    return (
      <div className="flex min-h-[58px] items-center px-4 py-3 md:pr-8 lg:pr-12">
        <Text chars={12} />
      </div>
    )
  }

  if (hasNoPaths) {
    return null
  }

  const isWithTable =
    !isTableTurnedOff &&
    (orderingType === OrderingType.DineIn ||
      orderingType === OrderingType.InVenue)

  return (
    <>
      {isWithTable && (
        <div className="flex flex-col md:w-auto">
          <div className="flex items-start gap-1">
            <span className="leading-[18px]"> {`\u2022`}</span>
            <div className="my-body-sm">
              <Button
                type="button"
                variant="link-primary"
                className="min-h-5 items-start gap-0"
                size="sm"
                aria-label={intl.formatMessage({
                  defaultMessage: 'Configure ordering type',
                  id: 'R0MNO0',
                })}
                onClick={handleOnClick}
                rightIcon={
                  <ChevronDownIcon
                    width={20}
                    height={20}
                    className="flex-shrink-0"
                  />
                }
              >
                <TableNumberLabel
                  shortText={tableShortText}
                  number={tableNumber}
                  area={cart?.tableArea}
                />
              </Button>
            </div>
          </div>
        </div>
      )}
    </>
  )
}
