import { useRouter } from 'next/router'

import { SheetSlug } from '@/lib/routes'

export const useCurrentSheet = () => {
  const router = useRouter()
  const sheet = router.query.sheet as SheetSlug | null

  return sheet
}
