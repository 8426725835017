import { RefreshOutlineIcon } from '@mr-yum/frontend-ui'
import React, { useCallback } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

import { EventType, useReorder } from '@/hooks/useReorder'

import { ActionBlockItem } from './ActionBlockItem'

interface Props {
  venueSlug: string
  orderId: string
  count: number
}

export const ReorderBlock = ({ venueSlug, orderId, count }: Props) => {
  const intl = useIntl()
  const { handleReorder, reorderFetching, reorderError } = useReorder(
    EventType.venueLanding,
  )

  const handleReorderClick = useCallback(() => {
    void handleReorder(venueSlug, orderId)
  }, [handleReorder, venueSlug, orderId])

  const heading = reorderError ? (
    <FormattedMessage defaultMessage="Oops! Try again" id="Brx7UQ" />
  ) : reorderFetching ? (
    <FormattedMessage defaultMessage="Reordering..." id="UugRIX" />
  ) : (
    <FormattedMessage defaultMessage="Reorder drinks" id="wRaxyc" />
  )

  return (
    <ActionBlockItem
      as="button"
      onClick={handleReorderClick}
      count={count}
      isLoading={reorderFetching}
      icon={<RefreshOutlineIcon />}
      aria-label={intl.formatMessage({
        defaultMessage: 'Reorder drinks button',
        id: 'wOtgku',
      })}
      heading={heading}
    />
  )
}
