import { RouteLink } from '@mr-yum/frontend-core/dist/services/routes'
import { ChevronRightIcon } from '@mr-yum/frontend-ui'
import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

import { FlexibleWaitTimesBadge } from '@/components/FlexibleWaitTimes/FlexibleWaitTimesLabel'
import { VenueLandingMenuCategoryFragment } from '@/gql/graphql'
import { useLogger } from '@/hooks/useLogger'
import { OrderingTypeSlug, routes } from '@/lib/routes'

interface Props {
  venueSlug: string
  orderingTypeSlug: OrderingTypeSlug
  unavailable?: boolean
  category: VenueLandingMenuCategoryFragment
}

export const MenuCategoryListItem = ({
  venueSlug,
  orderingTypeSlug,
  unavailable,
  category,
}: Props) => {
  const { logEvent } = useLogger()
  const intl = useIntl()

  return (
    <RouteLink
      key={category.id}
      route={routes.menuCategory}
      pathData={{
        venueSlug,
        orderingTypeSlug,
        category: category.slug,
      }}
    >
      <a
        className="ease-ou mb-2 flex w-full items-center justify-between rounded border bg-interactive-subtle-surface p-4 text-left text-inherit transition-all hover:bg-interactive-subtle-surface-hover hover:underline focus:outline-none focus-visible:ring-1"
        onClick={() => {
          logEvent('Clicked on category on venue landing page', {
            categoryFromDatabase: category,
          })
        }}
        aria-label={intl.formatMessage({
          defaultMessage: 'Menu category',
          id: '88OQkh',
        })}
      >
        <h2 className="mr-2 text-foreground">
          {category.name}

          {unavailable && (
            <span className="block">
              <FormattedMessage
                defaultMessage="Ordering not available"
                id="shXI1B"
              />
            </span>
          )}

          {!unavailable && category.isWaitTimeActive && (
            <span className="block">
              <FlexibleWaitTimesBadge
                estimationInMinutes={category.waitTimeEstimationInMinutes}
              />
            </span>
          )}
        </h2>

        <ChevronRightIcon />
      </a>
    </RouteLink>
  )
}
