import React, { useCallback, useContext, useEffect } from 'react'
import { FormattedMessage } from 'react-intl'

import { OrderingWindowsForm } from '@/components/Ordering/OrderingWindowsForm'
import { ResponsiveSheet } from '@/components/ResponsiveSheet'
import {
  useOrderingTypeContext,
  useVenueContext,
} from '@/contexts/VenueOrderContext'
import { CartLandingQuery, OrderingType } from '@/gql/graphql'
import { useLogger } from '@/hooks/useLogger'
import { CustomerStoreContext } from '@/stores/CustomerStore'

interface Props {
  open: boolean
  cart: CartLandingQuery['getCart']
  onOpenChange: () => void
}

export const OrderingWindowSheet = ({ open, cart, onOpenChange }: Props) => {
  const { logEvent } = useLogger()
  const { orderingType } = useOrderingTypeContext()
  const { venueSlug } = useVenueContext()

  const { showOrderingTypeModal } = useContext(CustomerStoreContext)
  useEffect(() => {
    showOrderingTypeModal()
  }, [showOrderingTypeModal])

  const handleOnSubmit = useCallback(() => {
    logEvent('Updated ordering window on venue landing page')
    onOpenChange()
  }, [logEvent, onOpenChange])

  const Title = useCallback(() => {
    if (orderingType === OrderingType.Delivery) {
      return (
        <FormattedMessage defaultMessage="Select delivery window" id="MxHwwY" />
      )
    } else if (orderingType === OrderingType.PickUp) {
      return (
        <FormattedMessage defaultMessage="Select pickup window" id="17C4ef" />
      )
    } else {
      return (
        <FormattedMessage defaultMessage="Select ordering window" id="namfZU" />
      )
    }
  }, [orderingType])

  return (
    <ResponsiveSheet open={open} title={<Title />} onOpenChange={onOpenChange}>
      <OrderingWindowsForm
        venueSlug={venueSlug}
        orderingType={orderingType}
        onClose={handleOnSubmit}
        startDate={cart?.orderingWindowStartDate}
      />
    </ResponsiveSheet>
  )
}
