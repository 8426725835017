import {
  createDinero,
  Currency,
} from '@mr-yum/frontend-core/dist/services/createDinero'
import React from 'react'

import { CartStaleWrapper } from '@/components/Cart/shared'
import { ItemQuantity } from '@/components/MenuItem/utils'
import { Image } from '@/components/Shared/Image'
import { CartItem } from '@/gql/graphql'

type Props = {
  isLoading?: boolean
  cartItem: CartItem
  currency?: Currency
  onItemClick: (item: CartItem) => void
} & Partial<ItemQuantity>

export const ReorderDrinkItem = ({
  isLoading,
  cartItem,
  currency,
  onItemClick,
}: Props) => {
  const cdnImage = cartItem.item?.cdnImage ?? cartItem.upsell?.menuItem.cdnImage

  return (
    <CartStaleWrapper loading={isLoading}>
      <div className="flex max-w-[100px] flex-col justify-start gap-2">
        {cdnImage && (
          <div
            className="inset relative h-24 w-24 overflow-hidden rounded-lg border border-subtle"
            role="button"
            onClick={() => onItemClick(cartItem)}
          >
            <Image
              alt=""
              image={{
                id: cdnImage.id,
                originalImagePath: cdnImage.originalImagePath,
                originalImageUrl: cdnImage.originalImageUrl,
              }}
              layout="fill"
              objectFit="cover"
            />
          </div>
        )}
        <div className="flex flex-col text-left">
          <p className="mb-1 capitalize my-label-sm">{cartItem.name}</p>
          {currency && cartItem?.item?.priceInCents && (
            <div className="flex items-center justify-between pr-4">
              <p
                className="text-foreground-subtle my-body-sm"
                data-testid="cart-item-total"
              >
                {createDinero(cartItem.item.priceInCents, currency).toFormat()}
              </p>
            </div>
          )}
        </div>
      </div>
    </CartStaleWrapper>
  )
}
