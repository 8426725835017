import { navigate } from '@mr-yum/frontend-core/dist/services/routes'
import { Currency } from 'dinero.js'
import React, { useCallback } from 'react'

import {
  CartItem,
  CartLandingQuery,
  OrderingType,
  VenueLandingAdditionalQuery,
} from '@/gql/graphql'
import { MenuItemWithSectionSlug, routes } from '@/lib/routes'

import { useVenueMenuCategory } from '../utils/venueUtils'
import { ReorderDrinkItem } from './ReorderDrinkItem'

interface Props {
  availableCartItems: VenueLandingAdditionalQuery['getAnotherRoundCartItems']
  venueSlug: string
  orderingType: OrderingType
  currency: Currency
  cart: CartLandingQuery['getCart']
  cartFetching: boolean
  orderingTypeSlug: string
}

export const ReorderDrinksCarousel = ({
  availableCartItems,
  venueSlug,
  currency,
  cart,
  cartFetching,
  orderingTypeSlug,
}: Props) => {
  const { data } = useVenueMenuCategory({
    cart,
    cartFetching,
  })

  const onItemClick = useCallback(
    async (cartItem: CartItem) => {
      const matchingCategory = data?.guestMenuCategories.find(
        (category) => category.id === cartItem.menuCategoryId,
      )
      if (matchingCategory) {
        const categorySlug = matchingCategory.slug
        const sectionSlug = matchingCategory.menuSections.find(
          (section) => section.id === cartItem.menuSectionId,
        )?.slug
        const itemSlug = cartItem.item?.slug

        if (categorySlug && sectionSlug && itemSlug) {
          void navigate(routes.menuItemModal, {
            venueSlug,
            orderingTypeSlug,
            category: categorySlug,
            sectionSlug,
            itemSlug,
            backToVenuePage: true,
          } as MenuItemWithSectionSlug)
        }
      }
    },
    [data?.guestMenuCategories, orderingTypeSlug, venueSlug],
  )

  if (!availableCartItems?.length) {
    return null
  }

  return (
    <div className="-mx-4 overflow-hidden">
      <div className="flex gap-3 overflow-auto px-4">
        {availableCartItems.map((item) => (
          <ReorderDrinkItem
            key={item.id}
            cartItem={item as CartItem}
            currency={currency}
            onItemClick={onItemClick}
          />
        ))}
      </div>
    </div>
  )
}
