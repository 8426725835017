import { applyUtmToUrl } from '@mr-yum/frontend-core/dist/support/links'
import { Button } from '@mr-yum/frontend-ui'
import { useRouter } from 'next/router'
import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

export const VenueCTAButton = ({
  text,
  url,
}: {
  text?: string | null
  url?: string | null
}) => {
  const router = useRouter()
  const intl = useIntl()

  if (router.query['utm_source'] === 'opentable') {
    return (
      <Button
        type="button"
        variant="secondary"
        onClick={() => router.back()}
        aria-label={intl.formatMessage({
          defaultMessage: 'Return to OpenTable',
          id: '7X8UMt',
        })}
        fullWidth
      >
        <FormattedMessage
          defaultMessage="Return to OpenTable"
          id="kg9HE/"
          description="OpenTable is the name of a booking platform, do not translate it"
        />
      </Button>
    )
  }

  if (text && url) {
    const anchorProps = {
      target: '_blank',
      rel: 'noreferrer noopener',
      href: applyUtmToUrl(url, { medium: 'landing-cta' }),
    }

    return (
      <Button
        variant="secondary"
        as="a"
        aria-label={intl.formatMessage({
          defaultMessage: 'Venue landing page call to action',
          id: 'Vv+4C5',
        })}
        fullWidth
        {...anchorProps}
      >
        {text}
      </Button>
    )
  }

  return null
}
