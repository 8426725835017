import { SparkleFilledIcon } from '@mr-yum/frontend-ui'
import React from 'react'

import { CurrentUserQuery } from '@/gql/graphql'

interface Props {
  fetching: boolean
  currentUser: CurrentUserQuery['currentUser']
}

export const UserGreeting = ({ fetching, currentUser }: Props) => {
  const firstName = currentUser?.name?.split(' ')[0]

  if (fetching) {
    return <div className="mb-2 h-5 w-24 rounded skeleton" />
  }
  return (
    <div className="mb-3 flex items-center justify-between">
      <span className="my-heading-sm">
        Hey {firstName && firstName + ' '}👋
      </span>
      <div className="flex items-center rounded-md  bg-semantic-emphasis-surface-subtle p-2 text-semantic-emphasis-foreground-bold">
        <SparkleFilledIcon className="mr-2 h-4 w-4" />
        <span className="my-label-sm">For you</span>
      </div>
    </div>
  )
}
