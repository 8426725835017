import { Button, ChevronDownIcon } from '@mr-yum/frontend-ui'
import React, { useCallback } from 'react'
import { useIntl } from 'react-intl'

import { CartValidator } from '@/components/Cart/validation'
import { Address } from '@/components/Ordering/Address'
import { Text } from '@/components/Skeleton/Text'
import { useSheetToggle } from '@/components/VenueV2/utils/sheetUtils'
import { venueToOrderingTypeItems } from '@/components/VenueV2/utils/venueUtils'
import { useOrderingTypeContext } from '@/contexts/VenueOrderContext'
import { OrderingType, VenueLandingQuery } from '@/gql/graphql'
import { useLogger } from '@/hooks/useLogger'

interface Props {
  venue: VenueLandingQuery['guestVenue']
  cart?: CartValidator | null
  isLoading: boolean
  hasNoPaths?: boolean
}

export const AddressButton = ({
  venue,
  cart,
  isLoading,
  hasNoPaths,
}: Props) => {
  const { logEvent } = useLogger()
  const sheetToggle = useSheetToggle()
  const intl = useIntl()

  const handleOnClick = useCallback(() => {
    logEvent('Clicked on address selector')
    void sheetToggle({
      sheet: 'address',
    })
  }, [logEvent, sheetToggle])

  const { orderingType } = useOrderingTypeContext()
  const { nonMenuCount, items } = venueToOrderingTypeItems(
    venue,
    orderingType,
    intl,
  )

  const hasMultipleOrderingTypes = items.length > 1

  if (isLoading || !cart) {
    return (
      <div className="flex min-h-[58px] items-center px-4 py-3 md:pr-8 lg:pr-12">
        <Text chars={12} />
      </div>
    )
  }

  if (hasNoPaths) {
    return null
  }

  if (nonMenuCount === 0) {
    return null
  }

  if (!hasMultipleOrderingTypes && orderingType === OrderingType.DineIn) {
    return null
  }

  if (orderingType !== OrderingType.Delivery) {
    return null
  }

  return (
    <Button
      type="button"
      variant="link-primary"
      size="sm"
      onClick={handleOnClick}
      className="gap-0"
      // eslint-disable-next-line formatjs/enforce-id
      aria-label={intl.formatMessage({
        defaultMessage: 'Select address',
        id: 'Ei5qD/',
      })}
      data-testid="select-address"
      rightIcon={
        <ChevronDownIcon width={20} height={20} className="flex-shrink-0" />
      }
    >
      {cart?.currentLocation ? (
        <Address
          className="max-w-[65vw] text-left"
          location={cart?.currentLocation}
        />
      ) : (
        'Select address'
      )}
    </Button>
  )
}
