import { RouteLink } from '@mr-yum/frontend-core/dist/services/routes'
import { UserGroupFilledIcon } from '@mr-yum/frontend-ui'
import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

import { useLogger } from '@/hooks/useLogger'
import { routes } from '@/lib/routes'

import { ActionBlockItem } from './ActionBlockItem'
import { ActionBlocksLoader } from './ActionBlocksLoader'

interface Props {
  venueSlug: string
  count: number
  isActionBlockLoading: boolean
  showStartTabBlock: boolean
}

export const StartTabBlock = ({
  venueSlug,
  count,
  isActionBlockLoading,
  showStartTabBlock,
}: Props) => {
  const intl = useIntl()
  const { logEvent } = useLogger()

  if (!showStartTabBlock) {
    return null
  }

  return (
    <>
      {isActionBlockLoading && <ActionBlocksLoader />}
      <div className="-mr-4 flex w-full gap-3 overflow-x-auto whitespace-nowrap pr-4 scrollbar-hide md:mr-0 md:pr-0 [&>a]:rounded-md [&>a]:py-1">
        <RouteLink route={routes.socialTabStart} pathData={{ venueSlug }}>
          <ActionBlockItem
            count={count}
            className={'max-w-[174px]'}
            icon={<UserGroupFilledIcon height={20} width={20} />}
            onClick={() =>
              logEvent('Clicked on start a tab on venue landing page')
            }
            aria-label={intl.formatMessage({
              defaultMessage: 'Start group order',
              id: 'w9prxu',
            })}
            heading={
              <FormattedMessage
                defaultMessage="Start group order"
                id="w9prxu"
              />
            }
            showIndicator={false}
          />
        </RouteLink>
      </div>
    </>
  )
}
