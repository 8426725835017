import { CenterSpinner } from '@mr-yum/frontend-ui'
import React, { useCallback } from 'react'
import { FormattedMessage } from 'react-intl'

import { ResponsiveSheet } from '@/components/ResponsiveSheet'
import { AddressForm } from '@/components/YourAddress/AddressForm'
import { CartLandingQuery } from '@/gql/graphql'
import { useLogger } from '@/hooks/useLogger'

interface Props {
  open: boolean
  cart: CartLandingQuery['getCart']
  cartFetching: boolean
  onOpenChange: () => void
}

export const AddressSheet = ({
  open,
  cart,
  cartFetching,
  onOpenChange,
}: Props) => {
  const { logEvent } = useLogger()

  const handleOnSubmit = useCallback(() => {
    logEvent('Updated address on venue landing page')
    onOpenChange()
  }, [logEvent, onOpenChange])

  return (
    <ResponsiveSheet
      open={open}
      title={
        <FormattedMessage defaultMessage="Enter delivery address" id="lQZ2Ad" />
      }
      onOpenChange={onOpenChange}
    >
      {cartFetching ? (
        <div className="p-4">
          <CenterSpinner />
        </div>
      ) : (
        <div className="p-4">
          <AddressForm
            onClose={handleOnSubmit}
            location={cart?.currentLocation}
          />
        </div>
      )}
    </ResponsiveSheet>
  )
}
