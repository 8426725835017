import { isServer } from '@mr-yum/frontend-core/dist/support/env'
import { Currency } from 'dinero.js'
import React, { useMemo } from 'react'
import { useQuery } from 'urql'

import {
  useOrderingTypeContext,
  useVenueContext,
} from '@/contexts/VenueOrderContext'
import {
  CartLandingQuery,
  CurrentUserDocument,
  VenueLandingAdditionalDocument,
  VenueLandingQuery,
} from '@/gql/graphql'

import { ReorderDrinksCarousel } from './ReorderDrinks.tsx/ReorderDrinksCarousel'
import { UserGreeting } from './UserGreeting'
import { deduplicateCartItems } from './utils/itemUtils'

interface Props {
  venue: VenueLandingQuery['guestVenue']
  cart: CartLandingQuery['getCart']
  cartFetching: boolean
}

const PersonalisedExperience = ({ venue, cart, cartFetching }: Props) => {
  const [{ data: userData, fetching: userDataFetching }] = useQuery({
    query: CurrentUserDocument,
    pause: isServer,
  })
  const { orderingType, orderingTypeSlug } = useOrderingTypeContext()
  const { venueSlug } = useVenueContext()
  const [{ data: additionalData }] = useQuery({
    query: VenueLandingAdditionalDocument,
    pause: isServer,
    variables: {
      venueSlug,
      orderingType,
    },
    requestPolicy: 'cache-first',
  })

  const reorderCartItems = additionalData?.getAnotherRoundCartItems
  const availableCartItems = useMemo(
    () =>
      reorderCartItems?.filter((cartItem) => {
        const { item, upsell, quantity } = cartItem

        return (
          quantity > 0 &&
          item?.isAvailable &&
          item.isAvailableForOrdering &&
          !upsell
        )
      }),
    [reorderCartItems],
  )

  const uniqueCartItems = deduplicateCartItems(availableCartItems)

  if (
    (!userData?.currentUser && !userDataFetching) ||
    !venue ||
    !uniqueCartItems?.length
  ) {
    return null
  }
  return (
    <div className="px-4 pt-4">
      <UserGreeting
        fetching={userDataFetching}
        currentUser={userData?.currentUser}
      />
      {!!uniqueCartItems?.length && (
        <ReorderDrinksCarousel
          availableCartItems={uniqueCartItems}
          venueSlug={venueSlug}
          orderingType={orderingType}
          currency={venue.currency as Currency}
          cart={cart}
          cartFetching={cartFetching}
          orderingTypeSlug={orderingTypeSlug}
        />
      )}
    </div>
  )
}

export default PersonalisedExperience
