import React, { memo } from 'react'

import { Text } from '@/components/Skeleton/Text'

import {
  ItemDescription,
  ItemName,
  ItemPriceWrapper,
  ListItemWrapper,
} from './shared'

export const ListItemSkeleton = memo(() => (
  <ListItemWrapper>
    <div className="relative h-auto md:h-0 md:pt-[40%]">
      <div className="left-0 top-0 flex h-full w-full flex-col py-5 text-foreground-subtle md:absolute md:px-5">
        <div className="h-full flex-1">
          <div className="flex h-full items-start justify-between md:flex-col">
            <div className="w-full flex-1">
              <ItemName>
                <Text chars={12} />
              </ItemName>
              <ItemDescription>
                <Text chars={24} />
              </ItemDescription>
            </div>
            <div className="flex flex-col items-end justify-between pl-4 md:w-full md:flex-row md:pl-0">
              <ItemPriceWrapper className="my-body-sm-bold">
                <Text chars={3} />
              </ItemPriceWrapper>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ListItemWrapper>
))
